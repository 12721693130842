import React from "react";
import "../css/Event.css";
import Footer from "../component/Footer";
import bday from "../images/camera/bird1.jpg";
import wed from "../images/camera/bird2.jpg";
import bird1 from "../images/birds/b1.jpg";
import bird2 from "../images/birds/b2.jpg";
import bird3 from "../images/birds/b3.jpg";
import pool1 from "../images/pool/p1.jpg";
import pool2 from "../images/pool/p2.jpg";
import pool3 from "../images/pool/p3.jpg";
import pool4 from "../images/pool/p4.jpg";
import pool5 from "../images/pool/p5.jpg";
import ps1 from "../images/photoshoot/photshoot.jpg";
import ps2 from "../images/photoshoot/photoshoot2.jpg";
import ps3 from "../images/photoshoot/photshoot3.jpg";
import ps4 from "../images/photoshoot/photoshoot4.jpg";
import w1 from "../images/wedding/w1.jpg";
import wh1 from "../images/wedding/wh1.jpg";
import w2 from "../images/wedding/wh2.jpg";
import wh2 from "../images/wedding/w2.jpg";
import w3 from "../images/wedding/w3.jpg";
import w4 from "../images/wedding/w4.jpg";
import w5 from "../images/wedding/w5.jpg";
import pt1 from "../images/wedding/pt1.jpg";
import pt2 from "../images/wedding/pt2.jpg";
import pt3 from "../images/wedding/pt3.jpg";
import pt4 from "../images/tent/tent1.jpg";
import pt5 from "../images/tent/tent2.jpg";
import f1 from "../images/food/f4.jpg";
import f2 from "../images/food/f2.jpg";
import f3 from "../images/food/f3.jpg";

// import bird4 from '../images/birds/bird4.jpg';
import game1 from "../images/games/g1.jpg";
import game2 from "../images/games/g2.jpg";
import game3 from "../images/games/g3.jpg";
import game4 from "../images/games/play1.jpg";
import game5 from "../images/games/bad.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
// import { Autoplay } from "swiper";
import Zoom from "react-reveal/Zoom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import MetaTags from "react-meta-tags";

const Gallery = () => {
  return (
    <div>
      {/* ========== Seo ============ */}

      <MetaTags>
        <title>
          Farm House And Resort For Family In Kalyan | Aaditya Resort
        </title>
        <meta title="Farm House And Resort For Family In Kalyan | Aaditya Resort" />
        <meta
          name="description"
          content="Immerse yourself in nature's embrace at Aaditya Resort  breathtaking Farm House and Resort For Family In Kalyan. Plan your perfect family retreat now!"
        />
        <meta
          name="keywords"
          content="farmhouse in kalyan,farmhouse in kalyan with swimming pool,farm house for couples,farm house for couples in kalyan,farm house and resort for family in kalyan"
        />
        <link
          rel="canonical"
          href="https://aadityaresort.com/farm-house-and-resort-for-family-in-kalyan"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="AadityaFarmResort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://aadityaresort.com/farm-house-and-resort-for-family-in-kalyan"
        />
        <meta
          property="og:description"
          content="Immerse yourself in nature's embrace at Aaditya Resort  breathtaking Farm House and Resort For Family In Kalyan. Plan your perfect family retreat now!"
        />
        <meta
          property="og:image"
          content="https://aadityaresort.com/static/media/logo1.7a13181af6ababd6e5b3.png"
        />
      </MetaTags>

      {/* ================== Seo End =========== */}
      <section className="headerSection3" id="">
        <div className="container">
          <div className="row ">
            <div className="headerText">
              <Zoom left cascade>
              <h1 className='d-none'>Farm House In Kalyan With Swimming Pool</h1>
                <h2> View gallery</h2>
                <p>
                  TO know more about Aditya Agrotourism, visit our gallery where
                  beautiful pictures defines facilities of Aditya Agrotourism.
                </p>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      <section className="activitiesSection mt-5 mb-lg-5 mb-2" id="about_us ">
        <div className="container pt-5">
          <div className="seven">
            <h2>Album</h2>
          </div>
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-4  mb-2">
              <div className="headerText d-flex justify-content-center">
                <h2>birds</h2>
              </div>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={wed} alt="farmhouse in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={bird3} alt="farmhouse in kalyan with swimming pool" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={bird2} alt="farm house for couples" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={bird1} alt="farm house for couples in kalyan" />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-4  mb-2">
              <div className="headerText d-flex justify-content-center">
                <h2>Pool</h2>
              </div>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={pool1} alt="farm house and resort for family in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pool2} alt="farmhouse in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pool3} alt="farmhouse in kalyan with swimming pool" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pool4} alt="farm house for couples" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pool5} alt="farm house for couples in kalyan" />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-4">
              <div className="headerText d-flex justify-content-center">
                <h2>Games</h2>
              </div>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={game1} alt="farm house and resort for family in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={game2} alt="farmhouse in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={game3} alt="farmhouse in kalyan with swimming pool" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={game4} alt="farm house for couples" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={game5} alt="farm house for couples in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={game2} alt="farm house and resort for family in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={game3} alt="farmhouse in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={game4} alt="farmhouse in kalyan with swimming pool" />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-4  mt-2 mb-2">
              <div className="headerText d-flex justify-content-center">
                <h2>Photoshoot</h2>
              </div>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={ps1} alt="farm house for couples" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ps2} alt="farm house for couples in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ps3} alt="farm house and resort for family in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ps4} alt="farmhouse in kalyan" />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-4  mt-2 mb-2">
              <div className="headerText d-flex justify-content-center">
                <h2>Wedding</h2>
              </div>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={w1} alt="farmhouse in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={wh1} alt="farmhouse in kalyan with swimming pool" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={w3}  alt="farm house for couples"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={w4} alt="farm house for couples in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={w5} alt="farm house and resort for family in kalyan"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={w2} alt="farmhouse in kalyan"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={wh2} alt="farmhouse in kalyan with swimming pool" />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-4  mt-2 mb-2">
              <div className="headerText d-flex justify-content-center">
                <h2>Parties</h2>
              </div>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={pt1} alt="farm house for couples" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pt2} alt="farm house for couples in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pt3} alt=" farm house and resort for family in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pt4} alt=" farmhouse in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={pt5} alt="farmhouse in kalyan with swimming pool" />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-4 ">
              <div className="headerText d-flex justify-content-center">
                <h2>Food</h2>
              </div>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={f1} alt="farm house for couples" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={f2} alt="farm house for couples in kalyan" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={f3} alt="farm house and resort for family in kalyan" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Gallery;
