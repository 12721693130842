import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar";
import Home from "./component/Home";
import About from "./component/About";
import Activities from "./component/Activities";
import Accomodation from "./component/Accomodation";
import Events from "./component/Events";
import Gallery from "./component/Gallery";

import Contact from "./component/Contact";
const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/farmhouse-in-kalyan" element={<About />} />
        <Route
          path="/farmhouse-in-kalyan-with-swimming-pool"
          element={<Activities />}
        />
        <Route path="/farm-house-for-couples" element={<Accomodation />} />
        <Route path="/farm-house-for-couples-in-kalyan" element={<Events />} />
        <Route
          path="/farm-house-and-resort-for-family-in-kalyan"
          element={<Gallery />}
        />
        <Route path="/farmhouse-in-mumbai" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
