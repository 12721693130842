import React from "react";
import "../css/Accomodation.css";
import "../css/Home.css";
import rest from "../images/camera/restaurant.jpg";
import deluxe from "../images/gallery/single- bed room.jpg";
import superDel from "../images/gallery/doublebed-room.jpg";
import couple from "../images/gallery/deluxe-room1.jpg";
import buffet from "../images/gallery/buffet-food.jpg";
import table from "../images/gallery/table.jpg";
import serve from "../images/gallery/room-serve.jpg";
import Footer from "../component/Footer";
import del1 from "../images/gallery/deluxe-room4.jpg";
import del2 from "../images/gallery/deluxe-room2.jpg";
import del3 from "../images/gallery/deluxe-room3.jpg";
import sdel1 from "../images/gallery/super-del.jpg";
import sdel2 from "../images/gallery/super-del2.jpg";
import sdel3 from "../images/gallery/super-del3.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Zoom";
import MetaTags from "react-meta-tags";

const Accomodation = () => {
  return (
    <>
      {/* ========== Seo ============ */}

      <MetaTags>
        <title>Farm House For Couple | Aaditya Resort</title>
        <meta title="Farm House For Couple | Aaditya Resort" />
        <meta
          name="description"
          content="Indulge in a romantic escape at our Farm House For Couples looking for a serene retreat. Unwind amidst nature and create cherished memories together."
        />
        <meta
          name="keywords"
          content="farmhouse in kalyan,farmhouse in kalyan with swimming pool,farm house for couples,farm house for couples in kalyan,farm house and resort for family in kalyan"
        />
        <link
          rel="canonical"
          href="https://aadityaresort.com/farm-house-for-couples"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="AadityaFarmResort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://aadityaresort.com/farm-house-for-couples"
        />
        <meta
          property="og:description"
          content="Indulge in a romantic escape at our Farm House For Couples looking for a serene retreat. Unwind amidst nature and create cherished memories together."
        />
        <meta
          property="og:image"
          content="https://aadityaresort.com/static/media/logo1.7a13181af6ababd6e5b3.png"
        />
      </MetaTags>

      {/* ================== Seo End =========== */}
      <section className="headerSection2" id="">
        <div className="container">
          <div className="row ">
            <div className="headerText">
              <Zoom left cascade>
              <h1 className='d-none'>farm-house-for-couples</h1>
                <h2> Feel Luxurious Stay </h2>
                <p>
                  At The Aditya Agro tourism Farm resort you'll enjoy the
                  peaceful, relaxing nature , combined with modern luxuries that
                  provide you with all of the comforts of home.
                </p>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      <section className="roomPage pt-lg-5 pt-2 pb-lg-5 pb-2" id="">
        <div className="container">
          <div className="row pt-lg-5 pt-2">
            <div className="col-lg-6">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiperRoom"
              >
                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={del1} alt="farm house for couples in kalyan" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={del2}  alt="farm house for couples in kalyan" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={del3}  alt="farm house for couples in kalyan" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={deluxe}  alt="farm house for couples in kalyan" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* </div> */}
            <div className="col-lg-6">
              <Zoom right>
                <div className="roomContent">
                  <h2>Deluxe rooms</h2>
                  <p>
                    Relax and feel the close connection to the natural
                    surroundings at our Aditya Agro tourism Farm. Your spacious
                    guestroom have geyser, free Wi-Fi, TV and many more
                    facilities.{" "}
                  </p>
                  <div className="row ">
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-wifi"></i>
                        <p>free wifi</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-television"></i>
                        <p>Television</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-bath"></i>
                        <p>Bathroom</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-snowflake-o"></i>
                        <p>Air conditioner</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-bed"></i>
                        <p>1 Bed </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
          <div className="row pt-lg-5 pt-2">
            <div className="col-lg-6">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiperRoom"
              >
                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={sdel2}  alt="farm house for couples in kalyan"/>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={sdel1}   alt="farm house for couples in kalyan"/>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={sdel3}  alt="farm house for couples in kalyan" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="banner">
                    <img src={superDel}  alt="farm house for couples in kalyan"/>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="col-lg-6">
              <Zoom>
                <div className="roomContent">
                  <h2>Super Deluxe rooms</h2>
                  <p>
                    Even when you're inside, you'll always be connected to the
                    outdoors at our super deluxe room. You'll find plush king
                    beds and luxurious bathroom with 24 hour hot water, free
                    Wi-Fi, TV and many more facilities.
                  </p>
                  <div className="row ">
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-wifi"></i>
                        <p>free wifi</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-television"></i>
                        <p>Television</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-bath"></i>
                        <p>bathroom</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-bed"></i>
                        <p>2 beds</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-institution"></i>
                        <p>1 hall</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <div className="amenities d-flex justify-content-center align-items-center">
                        <i className="fa fa-snowflake-o"></i>
                        <p>Air conditioner</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="row pt-lg-5 pt-2">
              <div className="col-lg-6">
                <div className="serviceImg">
                  <Zoom>
                    <img src={couple} alt="farmhouse in kalyan" />
                  </Zoom>
                </div>
              </div>
              <div className="col-lg-6">
                <Zoom>
                  <div className="roomContent">
                    <h2>Couple Rooms</h2>
                    <p>
                      Enjoy a romantic getaway in our couple room. Relax in room
                      with leisure time in air conditioning room and you have 24
                      hour hot water ,free Wi-Fi, TV and many more facilities
                      with admiring the river view. so you can enjoy a romantic
                      weekend of secluded comfort.
                    </p>
                    <div className="row ">
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="amenities d-flex justify-content-center align-items-center">
                          <i className="fa fa-wifi"></i>
                          <p>free wifi</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-6">
                        <div className="amenities d-flex justify-content-center align-items-center">
                          <i className="fa fa-television"></i>
                          <p>Television</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-6">
                        <div className="amenities d-flex justify-content-center align-items-center">
                          <i className="fa fa-bath"></i>
                          <p>bathroom</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-6">
                        <div className="amenities d-flex justify-content-center align-items-center">
                          <i className="fa fa-snowflake-o"></i>
                          <p>Air conditioner</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-6">
                        <div className="amenities d-flex justify-content-center align-items-center">
                          <i className="fa fa-bed"></i>
                          <p>1 Bed </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="restSection" id="">
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-6 m-0 p-0">
              <div className="restroImg">
                <img src={rest} alt="farmhouse in kalyan" />
              </div>
            </div>
            <div className="col-md-6 m-0 p-0">
              <div className="restroContent">
                <Zoom left cascade>
                  <h2>Feel taste of delicious food</h2>
                  <p>
                    At Aditya Agrotourism farmhouse we believe that our food
                    service are just as important as the decor and
                    entertainment. At our place we work hard to make sure our
                    guests receive delicious cuisine for their event. No matter
                    what your selection of food is, we guarantee your guests
                    will be dazzled.
                  </p>
                  <ul className="m-0 p-0">
                    <li>
                      <i className="fa fa-check"></i>Vegetarian & Non-vegetarian
                      unlimited buffet meal.
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Customized food Packages
                      available as per requirement.
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Coldrinks and Beverages are
                      available in Restaurant.
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Starters and proper Food
                      arrangements.{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i> Tea/Coffee and Snacks
                      available
                    </li>
                  </ul>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <--------------------------------------accomod------------------- */}
      <section className="foodSection pt-5" id="about_us ">
        <div className="container ">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={buffet} alt="farmhouse in kalyan"/>
                  </div>

                  <div className="aboutTitle ">
                    <h2>Buffet Catreing</h2>
                  </div>
                  <p>
                    We have High quality food served by our knowledgeable and
                    caring staff for our buffer catering.
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={table} alt="farmhouse in kalyan" />
                  </div>

                  <div className="aboutTitle ">
                    <h2>Table Service</h2>
                  </div>
                  <p>
                    The variety of dining options for kids and adults located
                    throughout our farmhouse can cater to any appetite: enjoy a
                    family meal of shareable plates.
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={serve} alt="farmhouse in kalyan" />
                  </div>

                  <div className="aboutTitle ">
                    <h2>Room Service</h2>
                  </div>
                  <p>
                    Our luxurious rooms are spacious and inviting, providing the
                    perfect respite after a day of play.
                  </p>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Accomodation;
