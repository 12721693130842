import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../component/Footer";
import "../css/Home.css";
import gard from "../images/gallery/garden.jpg";
import spool from "../images/gallery/poolNight-view.jpg";
import farm from "../images/gallery/full-view1.jpg";
import club from "../images/gallery/farm1.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "../css/About.css";
import story from "../images/story.jpg";
import visitor from "../images/visitors.jpg";

import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import MetaTags from "react-meta-tags";

const About = () => {
  return (
    <>
      {/* ========== Seo ============ */}

      <MetaTags>
        <title>
        Farm House In Kalyan | Aaditya Resort
        </title>
        <meta title="Farm House In Kalyan | Aaditya Resort" />
        <meta
          name="description"
          content="Escape the mundane and embark on an extraordinary journey at our enchanting Farm House In Kalyan. Discover a haven of peace and tranquility."
        />
        <meta
          name="keywords"
          content="farmhouse in kalyan,farmhouse in kalyan with swimming pool,farm house for couples,farm house for couples in kalyan,farm house and resort for family in kalyan"
        />
        <link rel="canonical" href="https://aadityaresort.com/farmhouse-in-kalyan" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="AadityaFarmResort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aadityaresort.com/farmhouse-in-kalyan" />
        <meta
          property="og:description"
          content="Escape the mundane and embark on an extraordinary journey at our enchanting Farm House In Kalyan. Discover a haven of peace and tranquility."
        />
        <meta
          property="og:image"
          content="https://aadityaresort.com/static/media/logo1.7a13181af6ababd6e5b3.png"
        />
      </MetaTags>

      {/* ================== Seo End =========== */}
      <section className="headerSection" id="">
        <div className="container">
          <div className="row ">
            <div className="headerText">
              <Zoom left cascade>
              <h1 className='d-none'>Farm House In Kalyan</h1>
                <h2> Our Story &amp; spaces</h2>
                <p>
                  Looking for a family vacation or a romantic Getaway come at
                  Aditya Agrotourism.
                </p>
              </Zoom>
            </div>
          </div>
        </div>
      </section>

      <section className="roomSection mt-lg-5 mb-lg-5 mt-2 mb-2" id="">
        <div className="container">
          <div className="row ">
            <div className="col-md-5 ">
              <div className="aboutTitle">
                <Slide left>
                  <h2>Story</h2>
                  <p>
                    Santosh is a founder and owner of Aditya Agro tourism Farm
                    resort. He completed his graduation in agriculture. The farm
                    owner created the farm With the help of employees, the farm
                    produces an amazing diversity of vegetables, herbs, nuts,
                    flowers, and fruits year-round. The farm also has a flock of
                    birds, chickens and sheep, a tribe of goats, and several
                    cows.
                  </p>
                  <p>
                    Owner goals is to integrate farm production with longer-term
                    environmental stewardship and give natural and serene
                    environment stay and vacation experience to people without
                    any pollution and unwanted noises so people can enjoy there
                    vacation with their partner , family and friends.{" "}
                  </p>
                </Slide>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="storyImg">
                <Slide right>
                  <img src={farm} alt="farmhouse in kalyan" />
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="foodSection pt-5" id="about_us ">
        <div class="container ">
          <div className="seven">
            <h2>Spaces</h2>
          </div>
          <div class="row d-flex justify-content-center ">
            <div class="col-md-4 ">
              <Zoom>
                <div class="aboutContent ">
                  <div class="aboutimg ">
                    <img src={gard} alt="farmhouse in kalyan"/>
                  </div>

                  <div class="aboutTitle ">
                    <h2>Lawn</h2>
                  </div>
                  <p>
                    An intimate setting surrounded by trees of beautiful
                    flowers, and fruits. Our outdoor Garden Lawn is perfect for
                    gatherings and exclusive dinning occasions.
                  </p>
                </div>
              </Zoom>
            </div>
            <div class="col-md-4 ">
              <Zoom>
                <div class="aboutContent ">
                  <div class="aboutimg ">
                    <img src={spool} alt="farmhouse in kalyan" />
                  </div>

                  <div class="aboutTitle ">
                    <h2>Swimming Pool</h2>
                  </div>
                  <p>
                    No matter what the weather outside is doing, our farmhouse
                    keeps the indoor water park fun flowing 365 days per year.
                    Splash, swim, and slide with the whole family,
                  </p>
                </div>
              </Zoom>
            </div>
            <div class="col-md-4 ">
              <Zoom>
                <div class="aboutContent ">
                  <div class="aboutimg ">
                    <img src={club} alt="farmhouse in kalyan" />
                  </div>

                  <div class="aboutTitle ">
                    <h2>Farming</h2>
                  </div>
                  <p>
                    We grows each year, providing you a healthy home harvest of
                    the beautiful flowers and freshest tomatoes, vegetables,
                    fruits, and berries.{" "}
                  </p>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutpageSection mt-5 pb-lg-5 pb-2 " id="about_us ">
        <div className="container">
          <div className="seven">
            <h2>Testimonials</h2>
          </div>
          <div className="row ">
            <div className="col-lg-7 ">
              <Slide left>
                <div className="testImage ">
                  <img src={visitor} alt="farmhouse in kalyan"/>
                </div>
              </Slide>
            </div>
            <div className="col-lg-5 mt-5">
              <Swiper
                slidesPerView={"auto"}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Slide right>
                    <div className="aboutContent ">
                      <div className="aboutTitle ">
                        <h2>Best place to visit</h2>
                        <p>
                          One of the best place to visit with your family and
                          friends. Best service and polite staff. Food taste is
                          good. Reasonable and convenient place to visit.
                        </p>
                        <h2>Prajkta Rajput</h2>
                      </div>
                    </div>
                  </Slide>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="aboutContent ">
                    <div className="aboutTitle ">
                      <h2>Awesome Place</h2>
                      <p>
                        Awesome place, Food quality best, swimming pool very
                        clean,farm house so beatiful nature, plantition.
                      </p>
                      <h2>Kapil Bherale</h2>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="aboutContent ">
                    <div className="aboutTitle ">
                      <h2>comfortable room</h2>
                      <p>
                        Excellent farmhouse and best service.We booked for 10
                        people amazing experience. They take at most care of
                        everything food quality of awesome with clean rooms and
                        nice ambience. Must visit place we will Suggest for
                        those who want to relax and enjoy the Nature.
                      </p>
                      <h2> Siddhanth Ghusale</h2>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="aboutContent ">
                    <div className="aboutTitle ">
                      <h2>Proper cleanliness</h2>
                      <p>
                        It is great place to hangout with facility of swimming
                        pool and rain dance. Food here is also good with quite
                        co-operative staff. Must visit place to hangout with
                        proper cleanliness.
                      </p>
                      <h2> Yogen Masurkar</h2>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="aboutContent ">
                    <div className="aboutTitle ">
                      <h2>Amazing Ambience</h2>
                      <p>
                        Spacious surrounding, amazing ambience, vast pool and
                        delicious food makes this place very special. Would like
                        to recommend Aditya agro fas..For chilling out on
                        weekends with family and friends or for parties and
                        events.Aditya agro is the best place to cater to all the
                        needs.
                      </p>
                      <h2> Sameepa Ausarkar</h2>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="aboutContent ">
                    <div className="aboutTitle ">
                      <h2>Good place for family and friends</h2>
                      <p>
                        I had visited with family it's good place for family
                        friendly, clean pool, well maintained rain dance, huge
                        lawns
                      </p>
                      <h2>Shubham Bhalerao</h2>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
