import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../css/Home.css";
import Footer from "../component/Footer";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import pool from "../images/gallery/poolside-party3.jpg";
import ghat from "../images/gallery/malshej-Ghat.jpg";
import pic from "../images/camera/IMG_1188.jpg";

import fort from "../images/gallery/sinhagad-fort.jpg";
import rest from "../images/camera/restaurant.jpg";
import Ticon1 from "../images/coconut-tree.png";
import Ticon3 from "../images/fruit.png";
import Ticon4 from "../images/swimming-pool.png";
import Ticon5 from "../images/swing.png";
import Ticon6 from "../images/bird.png";
import Ticon7 from "../images/dj.png";
import banquet from "../images/gallery/banq.jpg";
import Ticon9 from "../images/champagne-glass.png";
import Ticon10 from "../images/board-game.png";
import Ticon11 from "../images/selfie.png";
import Ticon12 from "../images/rain.png";
import Ticon13 from "../images/signage.png";
import scene1 from "../images/gallery/manas-mandir.jpg";
import scene2 from "../images/gallery/dwedding4.jpg";
import locate from "../images/gallery/entrance.jpg";
import cottage from "../images/gallery/cottage.jpg";
import farm from "../images/gallery/farm-view.jpg";
import lawn from "../images/gallery/landscape-garden.jpg";
import MetaTags from "react-meta-tags";

import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";

// import required modules
import { Navigation } from "swiper";
const Home = () => {
  return (
    <>
      {/* ========== Seo ============ */}

      <MetaTags>
        <title>Best Farm And Resort with best service in Kalyan| Aaditya Resort</title>
        <meta title="Best Farm And Resort with best service in Kalyan| Aaditya Resort" />
        <meta name="description" content="Aaditya Farm and Resort at Kalyan provides best activities for the clients.Make your holiday plan memorable only at our best farm and resort. Call us now !!"/>
        <meta name="keywords" content="farmhouse in kalyan,farmhouse in kalyan with swimming pool,farm house for couples,farm house for couples in kalyan,farm house and resort for family in kalyan"/>
        <link rel="canonical" href="https://aadityaresort.com/"/>
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="AadityaFarmResort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aadityaresort.com/" />
        <meta
          property="og:description"
          content="Aaditya Farm and Resort at Kalyan provides best activities for the clients.Make your holiday plan memorable only at our best farm and resort. Call us now !!"
        />
        <meta
          property="og:image"
          content="https://aadityaresort.com/static/media/logo1.7a13181af6ababd6e5b3.png"
        />
      </MetaTags>

      {/* ================== Seo End =========== */}

      <div className="banner">
        <img src={pic} alt="aaditya resort"/>
        <div className="tagline">
          <Zoom left cascade>
          <h1 className='d-none'>Aaditya Resort Farm House In Kalyan</h1>
            <h2>Feel The love of nature at Aditya Agrotourism</h2>
            <p>
              Come and stay at Aditya agrotourism farmhouse and bring
              unforgettable memories with you from Aditya Agrotourism
            </p>
          </Zoom>
        </div>
      </div>


      {/* ----------------------------------------specialities---------------------------- */}

      <section className="why_hair_trans pt-lg-5 pt-5 pb-lg-5 pb-2">
        <div className="container">
          <div className="seven">
            <h2>Specialities</h2>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="body why_desktop_view">
                <div className="row text-center justify-content-center">
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon1} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>coconut trees</span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon3} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>Fresh fruits</span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6  mart30">
                      <div className="icon1">
                        <img src={Ticon4} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>Swimming Pool</span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon5} className="img-fluid"  alt="farmhouse in kalyan"/>
                      </div>
                      <p>
                        <span>Lawn </span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6  mart30">
                      <div className="icon1">
                        <img src={Ticon6} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>Birds</span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon7} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>Dj music</span>
                      </p>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="body why_desktop_view">
                <div className="row text-center justify-content-center">
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon9} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>Parties</span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon10} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>Games </span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon11} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>selfie point</span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6 mart30">
                      <div className="icon1">
                        <img src={Ticon12} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span>rain dance </span>
                      </p>
                    </div>
                  </Zoom>
                  <Zoom>
                    <div className="col-md-3 col-lg-2 col-6  mart30">
                      <div className="icon1">
                        <img src={Ticon13} className="img-fluid" alt="farmhouse in kalyan" />
                      </div>
                      <p>
                        <span> parking</span>
                      </p>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homeSection pt-lg-5 pt-5 pb-lg-5 pb-2" id="about_us ">
        <div className="container">
          <div className="seven">
            <h2>Activities</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-2">
              <div className="testImage ">
                <img src={cottage} alt="farmhouse in kalyan" />
                <Slide bottom>
                  <div className="serviceCard mt-5 ">
                    <h2>AC Cottages & villas</h2>
                    <p>
                      Enjoy the privacy of your own villa and AC cottage the
                      house parties where fellow guests get together to enjoy
                      anything from a gastronomic dinner, a party, bonfire, rain
                      dance, swimming pool, river view and many more attractions
                      .
                    </p>
                  </div>
                </Slide>
              </div>
            </div>
            <div className="col-lg-6 testimonialSection ">
              <div className="testImage">
                <img src={farm} alt="farmhouse in kalyan" />
                <Slide bottom>
                  <div className="serviceCard mt-5">
                    <h2>Agriculture farm</h2>
                    <p>
                      {" "}
                      The farm close to nature, there are different types of
                      plants like fruit flower and scenery plants and there are
                      of different different types of animal and birds.{" "}
                    </p>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-6 mb-2">
              <div className="testImage ">
                <img src={rest} alt="farmhouse in kalyan" />
                <Slide bottom>
                  <div className="serviceCard mt-5">
                    <h2>Restaurant (veg & non-veg)</h2>
                    <p>
                      As our honoured guests, we want to treat you like the
                      royalty that you are. In the restaurant, you have a
                      nourishing and hand-crafted menu.{" "}
                    </p>
                  </div>
                </Slide>
              </div>
            </div>
            <div className="col-lg-6 testimonialSection ">
              <div className="testImage ">
                <img src={lawn} alt="farmhouse in kalyan" />
                <Slide bottom>
                  <div className="serviceCard mt-5">
                    <h2>Garden With Lawn</h2>
                    <p>
                      An intimate setting surrounded by trees of beautiful
                      flowers, and fruits .our outdoor Garden Lawn is perfect
                      for gatherings and exclusive dining occasions.
                    </p>
                  </div>
                </Slide>
              </div>
            </div>
            <div className="col-lg-6 testimonialSection mt-2">
              <div className="testImage ">
                <img src={banquet} alt="farmhouse in kalyan" />
                <Slide bottom>
                  <div className="serviceCard mt-5">
                    <h2>Banquet Hall</h2>
                    <p>
                      Our banquet hall provides you with the convenience to own
                      your party and your guests. Planning for your banquet can
                      be an overstretch but, our banquet will be in a position
                      to accommodate your party with the proper size of guests.
                    </p>
                  </div>
                </Slide>
              </div>
            </div>
            <div className="col-lg-6 testimonialSection mt-2">
              <div className="testImage ">
                <img src={pool} alt="farmhouse in kalyan"/>
                <Slide bottom>
                  <div className="serviceCard mt-5">
                    <h2>Swimming Pool</h2>
                    <p>
                      Throw your Night pool party ,New year party, etc at our
                      huge pool side area. Need a space for Parties and events
                      The Aditya Agro is the perfect solution!
                    </p>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="visitSection pt-lg-5 pt-2 pb-lg-5 pb-2" id="">
        <div className="container">
          <div className="seven">
            <h2>Sightseeing</h2>
          </div>

          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="aboutSight">
                <div className="sightImg">
                  <img src={ghat} alt="farmhouse in kalyan" />
                  <div className="sightTitle ">
                    <h2>Malshej Ghat</h2>
                    <p>
                      Malshej Ghat is a mountain pass located on Maharashtra's
                      Kalyan–Ahmednagar Road in the Western Ghats range. The
                      site is home to hundreds of various species of flora and
                      fauna, particularly avian species such as quails, rails,
                      crakes, flamingos, and cuckoos.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="aboutSight">
                <div className="sightImg">
                  <img src={fort} alt="farmhouse in kalyan" />
                </div>
                <div className="sightTitle ">
                  <h2>Chatrapati shivaji Maharaj Fort</h2>
                  <p>
                    One of the known historic place in Kalyan, it's the famous
                    fort of Shivani maharaja and the temple of goddess Durga is
                    very well maintained. During Navratri, there is a long queue
                    since 4 a.m in the morning for devi Darshan and there are
                    fairs nearby.lot of traffic, rush during these nine days
                    with numerous stalls for imitation jewellery especially
                    useful for garbha nights as well as food stamps too. Kids
                    can have fun on toy rides. Happening place during navratri
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="aboutSight ">
                <div className="sightImg ">
                  <img src={scene1} alt="farmhouse in kalyan" />
                  <div className="sightTitle ">
                    <h2>Manas Mandir</h2>
                    <p>
                      Really awesome place to find some peace of mind. Manas
                      Mandir Is Situated In Shahapur Taluka Of Thane District.
                      It is the Asia’s second biggest Jain Temple, Just 3 Km
                      away from Asangaon station, About 45 Minutes From Kalyan
                      Station by road. The Temple is of Shri Aadishwar Bhagwan
                      (1st among the 24 Jain Tirthankars).
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="aboutSight">
                <div className="sightImg">
                  <img src={scene2} alt="farmhouse in kalyan" />
                  <div className="sightTitle ">
                    <h2>Ulhas River</h2>
                    <p>
                      It rises at the foothills of the Sahyadris near Karjat in
                      the Western Ghats Range and starts flowing in the west
                      direction. It enters the Thane and Raigad districts of
                      Maharastra between Kulgaon-Badlapur towards the north of
                      Ulhasnagar and then moves towards Kalyan. Then the river
                      turns in the west direction towards Thane and a channel
                      connects it to the Thane Creek.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="aboutSight">
                <div className="sightImg">
                  <img src={pic} alt="farmhouse in kalyan" />
                </div>
                <div className="sightTitle ">
                  <h2></h2>
                  <p></p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section class="serviceinfoSection" id="about_us">
        <div class="container">
          <div class="row storyImg">
            <div class="col-lg-6">
              <div class="serviceImg">
                <Slide left>
                  <img src={locate} alt="farmhouse in kalyan and Resort" />
                </Slide>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="roomContent">
                <Slide right>
                  <h2>Somethigs to remember</h2>
                  <ul>
                    <li>
                      {" "}
                      <i className="fa fa-check">
                        &nbsp; Kindly submit the ID proof (Any Government
                        Approved) at the time of Check-In.
                      </i>
                    </li>
                    <li>
                      {" "}
                      <i className="fa fa-check">
                        &nbsp; Kindly Carry Proper Swimming Costume to Use the
                        Swimming Pool.
                      </i>
                    </li>
                    <li>
                      {" "}
                      <i className="fa fa-check">
                        &nbsp; Drinks are not allowed in pool (separate sitting
                        is available near pool Deck)
                      </i>
                    </li>
                    <li>
                      {" "}
                      <i className="fa fa-check">
                        &nbsp;Any guest for dinner and lunch purpose will have
                        to pay table charge.
                      </i>
                    </li>
                    <li>
                      {" "}
                      <i className="fa fa-check">
                        &nbsp;Pool close time 12 am to 07 am.
                      </i>
                    </li>
                    <li></li>
                  </ul>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
