import React from "react";
// import {Link} from 'react-router-dom'
import "../css/Navbar.css";
import logo from "../images/logo1.png";
import { NavLink, Link } from "react-router-dom";
const Navbar = () => {
  const navLinkStyle = ({ isActive }) => {
    return {
      color: isActive ? " rgb(219 37 26)" : "rgb(58 157 98)",
    };
  };
  return (
    <>
      <nav class="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link to="/">
            <a className="navbar-brand" href="logo">
              <img src={logo} alt="logo" />
            </a>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink style={navLinkStyle} to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink style={navLinkStyle} to="/farmhouse-in-kalyan">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  style={navLinkStyle}
                  to="/farmhouse-in-kalyan-with-swimming-pool"
                >
                  Activities
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink style={navLinkStyle} to="/farm-house-for-couples">
                  Accomodation
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  style={navLinkStyle}
                  to="/farm-house-for-couples-in-kalyan"
                >
                  Events
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  style={navLinkStyle}
                  to="/farm-house-and-resort-for-family-in-kalyan"
                >
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink style={navLinkStyle} to="/farmhouse-in-mumbai">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
