import React from "react";
import "../css/Event.css";
import Footer from "../component/Footer";
import bday from "../images/gallery/bday.jpg";
import wed from "../images/gallery/wedding-stage4.jpg";
import anniv from "../images/gallery/anniversary.jpg";
import pshoot from "../images/gallery/photoshoot2.jpg";
import sevents from "../images/gallery/corporate-events.jpg";
import gettog from "../images/gallery/get-together.jpg";
import dwed from "../images/gallery/dwedding5.jpg";
import cmas from "../images/gallery/poolside-partymain.jpg";
import con from "../images/gallery/cottage1.jpg";
import cot from "../images/gallery/con-hall.jpg";
import banq from "../images/gallery/banq.jpg";
import dorm from "../images/gallery/selfie-point.jpg";
import newy from "../images/gallery/new-year.jpg";

import Zoom from "react-reveal/Zoom";
import MetaTags from "react-meta-tags";

const Events = () => {
  return (
    <>
      {/* ========== Seo ============ */}

      <MetaTags>
        <title>Farm House For Couples In Kalyan  | Aaditya Resort</title>
        <meta title="Farm House For Couples In Kalyan  | Aaditya Resort" />
        <meta
          name="description"
          content="Aaditya Resort Farm House For Couples In Kalyan offers the perfect retreat for lovebirds seeking tranquility and privacy ."
        />
        <meta
          name="keywords"
          content="farmhouse in kalyan,farmhouse in kalyan with swimming pool,farm house for couples,farm house for couples in kalyan,farm house and resort for family in kalyan"
        />
        <link
          rel="canonical"
          href="https://aadityaresort.com/farm-house-for-couples-in-kalyan"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="AadityaFarmResort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://aadityaresort.com/farm-house-for-couples-in-kalyan"
        />
        <meta
          property="og:description"
          content="Aaditya Resort Farm House For Couples In Kalyan offers the perfect retreat for lovebirds seeking tranquility and privacy ."
        />
        <meta
          property="og:image"
          content="https://aadityaresort.com/static/media/logo1.7a13181af6ababd6e5b3.png"
        />
      </MetaTags>

      {/* ================== Seo End =========== */}
      <section className="headerSection3" id="">
        <div className="container">
          <div className="row ">
            <div className="headerText">
              <Zoom left cascade>
              <h1 className='d-none'>farm-house-and-resort-for-family-in-kalyan</h1>
                <h2> Plan and Celebrate Events</h2>
                <p>
                  Aditya Agrotourism farm in Kalyan is a place where magic
                  happens. Celebrate your most memorable occasions with us and
                  have something
                </p>
              </Zoom>
            </div>
          </div>
        </div>
      </section>

      <section class="homeSection" id="about_us ">
        <div class="container ">
          <div class="row ">
            <div class="col-lg-7 ">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="card m-0 p-0">
                    <div class="card-img-top ">
                      <img src={cot} alt=" verandah" />
                      <div class="overlay">
                        <h2>Party Lawn</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="card m-0 p-0 ">
                    {/* <div class="card-img-top "> */}
                    <img src={con} alt="deck" />
                    <div class="overlay">
                      <h2>AC Cottages</h2>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div class="col-md-6 col-12 ">
                  <div class="card m-0 p-0 ">
                    {/* <div class="card-img-top "> */}
                    <img src={banq} alt="course-dinner" />
                    <div class="overlay">
                      <h2>Banquet hall</h2>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="card m-0 p-0">
                    {/* <div class="card-img-top "> */}
                    <img src={dorm} alt="terrce" />
                    <div class="overlay">
                      <h2>Selfie Point</h2>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 p-0 ">
              <div class="aboutContent ">
                <h2>plan your event</h2>
                <p>
                  Aditya Agrotourism farm in Kalyan is a place where magic
                  happens. Celebrate your most memorable occasions with us and
                  have something to brag about to your friends. Weddings,
                  Anniversaries, Engagements, Corporate Events, Bridal/Baby
                  Showers and even Milestones. We will accommodate all of your
                  needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="activitiesSection mt-5 mb-lg-5 mb-2" id="about_us ">
        <div className="container pt-5">
          <div className="seven">
            <h2>Book Your Event</h2>
          </div>
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={gettog} alt="farmhouse in kalyan" />
                    <div class="overlay">
                      <h2>Get Together</h2>
                    </div>
                  </div>
                  {/* <div className="aboutTitle ">
                  <h2>Hydroponics</h2>
                </div> */}
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={anniv} alt="farmhouse in kalyan" />
                    <div class="overlay">
                      <h2>Anniversary</h2>
                    </div>
                  </div>
                  {/* <div className="aboutTitle ">
                  <h2>Organic plantation & Gardening</h2>
                </div> */}
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={bday} alt="farmhouse in kalyan"/>
                    <div class="overlay">
                      <h2>birthday</h2>
                    </div>
                  </div>
                  {/* <div className="aboutTitle ">
                  <h2>Swimming pool</h2>
                </div> */}
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={cot} alt="farmhouse in kalyan" />
                    <div class="overlay">
                      <h2>Wedding</h2>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={pshoot} alt="farmhouse in kalyan" />
                    <div class="overlay">
                      <h2>Pre wedding shoot</h2>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={dwed}  alt="farmhouse in kalyan"/>
                    <div class="overlay">
                      <h2>Destination wedding</h2>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={cmas} alt="farmhouse in kalyan" />
                    <div class="overlay">
                      <h2>Christmas Celebration</h2>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={newy} alt="farmhouse in kalyan" />
                    <div class="overlay">
                      <h2>New year party</h2>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={sevents} alt="farmhouse in kalyan" />
                    <div class="overlay">
                      <h2>Social Events</h2>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Events;
