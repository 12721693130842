import React from "react";
import Footer from "../component/Footer";
import enter from "../images/gallery/entrance2.jpg";
import pool from "../images/gallery/pool-view1.jpg";
import poolside from "../images/gallery/poolside-party.jpg";
import rain from "../images/gallery/rain-dance.jpg";
import river from "../images/gallery/dwedding5.jpg";
import pg from "../images/gallery/baby-pool.jpg";
import fire from "../images/camera/bonfire.jpg";
import Lgarden from "../images/gallery/landscape-garden.jpg";
import baby from "../images/gallery/playground.jpg";
import hydroponic from "../images/hydroponic.jpg";
import "../css/Activities.css";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import MetaTags from "react-meta-tags";

const Activities = () => {
  return (
    <>
      {/* ========== Seo ============ */}

      <MetaTags>
        <title>Farm House In Kalyan With Swimming Pool | Aaditya Resort</title>
        <meta title="Farm House In Kalyan With Swimming Pool | Aaditya Resort" />
        <meta
          name="description"
          content="Experience ultimate relaxation and luxury at our Farm House in Kalyan with a private swimming pool. Book now for a rejuvenating getaway."
        />
        <meta
          name="keywords"
          content="farmhouse in kalyan,farmhouse in kalyan with swimming pool,farm house for couples,farm house for couples in kalyan,farm house and resort for family in kalyan"
        />
        <link
          rel="canonical"
          href="https://aadityaresort.com/farmhouse-in-kalyan-with-swimming-pool"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="AadityaFarmResort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://aadityaresort.com/farmhouse-in-kalyan-with-swimming-pool"
        />
        <meta
          property="og:description"
          content="Experience ultimate relaxation and luxury at our Farm House in Kalyan with a private swimming pool. Book now for a rejuvenating getaway."
        />
        <meta
          property="og:image"
          content="https://aadityaresort.com/static/media/logo1.7a13181af6ababd6e5b3.png"
        />
      </MetaTags>

      {/* ================== Seo End =========== */}
      
      <section className="headerSection1" id="">
        <div className="container">
          <h1 className="d-none">Farm House In Kalyan With Swimming Pool</h1>
          <div className="row ">
            <div className="headerText">
              <Zoom left cascade>
                <h2> Fun and to do activities </h2>
                <p>
                  Aditya Agro Farm offers countless activities and adventures to
                  fulfil your every desire as actively as your heart desires.
                  Explore our activities and adventure section to find the
                  perfect experience during your stay. If you can't find what
                  you're looking for here, just ask.
                </p>
              </Zoom>
            </div>
          </div>
        </div>
      </section>

      {/* ================= serviceinfo Section ============== */}
      <section className="serviceinfoSection" id="about_us">
        <div className="container">
          <div className="row storyImg">
            <div className="col-lg-6 c0l-12">
              <Slide left>
                <div className="serviceImg">
                  <img src={enter} alt="image" />
                </div>
              </Slide>
            </div>
            <div className="col-lg-6 col-12 pt-5">
              <Slide right>
                <div className="roomContent">
                  <h2>Welcome to Aditya agrotourism</h2>
                  <p>
                    Looking to reconnect this summer and spend some great time
                    near a sprawling farm in a place far away from work
                    deadlines and traffic jams? Come stay with us at Aditya
                    Agro! We offer full-service amenities such as electricity
                    and hookups and pull-through sites for all groups.
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </section>

      {/* ================= Activities Section ============== */}
      <section className="activitiesSection mt-5 " id="about_us ">
        <div className="container pt-5">
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={hydroponic} alt=" Aaditya Resort" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>Hydroponics</h2>
                  </div>
                  <p>
                    This hydroponic farm is part of Aditya agrotourism farm
                    resort that provides fresh salad crops to our restaurants.{" "}
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={Lgarden} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>Organic plantation & Gardening</h2>
                  </div>
                  <p>
                    Come to the Aditya Agro tourism Farm resort and experience
                    the organic plantation and gardening.{" "}
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={pool} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>Swimming pool</h2>
                  </div>
                  <p>
                    No matter what the weather outside is doing, our farmhouse
                    at keeps the indoor water park fun flowing 365 days per
                    year. Splash, swim, and slide with the whole family,{" "}
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={baby} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>Kids area</h2>
                  </div>
                  <p>
                    Your kids will have the best time in our well though play
                    area.{" "}
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={rain} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>rain dance</h2>
                  </div>
                  <p>
                    Keep the fun going on our Resort with the rain dance. Full
                    masti and fun with friends and family with rain dance and
                    music.
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={pg} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>indoor and outdoor games</h2>
                  </div>
                  <p>
                    Aditya Agro tourism Resort was founded to provide fun
                    entertaining high quality indoor and outdoor games for you
                    and your family and friends and kids.
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={fire} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>bonfire</h2>
                  </div>
                  <p>
                    Upgrade to a bonfire experience just outside your room.
                    sit,relax and let us do the work while you gaze at the
                    Aditya Agro tourism Resort in kalyan.
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={poolside} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>Pool Side music</h2>
                  </div>
                  <p>
                    A beautiful decoration with lights and great sound music can
                    make your feet move on beats. We combine bespoke genre usage
                    and DJ music choice based on your requirements.
                  </p>
                </div>
              </Zoom>
            </div>
            <div className="col-md-4 ">
              <Zoom>
                <div className="aboutContent ">
                  <div className="aboutimg ">
                    <img src={river} alt="farmhouse in kalyan" />
                  </div>
                  <div className="aboutTitle ">
                    <h2>Wedding</h2>
                  </div>
                  <p>
                    We can do proper arrangements of wedding and party to make
                    your event best.
                  </p>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Activities;
