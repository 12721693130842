import React from "react";
import "../css/Footer.css";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";

const Footer = () => {
  return (
    <>
      <footer className="footer-distributed">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <Zoom cascade>
                <div className="footer-left">
                  <p className="footer-links">
                    <Link href="/" to="/" className="link-1">
                      Home
                    </Link>
                    <Link href="/farmhouse-in-kalyan" to="/farmhouse-in-kalyan">
                      About
                    </Link>

                    <Link
                      href="/farmhouse-in-kalyan-with-swimming-pool"
                      to="/farmhouse-in-kalyan-with-swimming-pool"
                    >
                      Activities
                    </Link>
                    <Link
                      href="/farm-house-for-couples"
                      to="/farm-house-for-couples"
                    >
                      Accomodation
                    </Link>
                    <Link
                      href="/farm-house-for-couples-in-kalyan"
                      to="/farm-house-for-couples-in-kalyan"
                    >
                      Events
                    </Link>
                    <Link
                      href="/farm-house-and-resort-for-family-in-kalyan"
                      to="/farm-house-and-resort-for-family-in-kalyan"
                    >
                      Gallery
                    </Link>
                    <Link href="/farmhouse-in-mumbai" to="/farmhouse-in-mumbai">
                      Contact
                    </Link>
                  </p>
                  <p className="footer-company-name">
                    {" "}
                    <a href="https://skdm.in/" target="blank">
                      Developed by Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </Zoom>
            </div>

            <div className="col-md-4">
              <Zoom cascade>
                <div className="footer-center">
                  <div class="address">
                    <i className="fa fa-map-marker" />
                    <p>
                      Taluka Gramin, Aaditya Agro Farm and resort Rayate village
                      Kalyan Murbad highway ,Next to Dahagaon fata Rayate
                      village, Kalyan, Maharashtra 421502
                    </p>
                  </div>

                  <div>
                    <i className="fa fa-phone" />
                    <p>
                      <a href="tel:+91 84128 11717">+91 84128 11717</a>
                    </p>
                    <br />
                    <p>
                      +91 84128 11717/
                      <br />
                      +91 8779078070/+91 9226461943
                    </p>
                  </div>

                  <div>
                    <i className="fa fa-envelope" />
                    <p>
                      <a href="mailto:support@company.com">
                        support@company.com
                      </a>
                    </p>
                  </div>
                </div>
              </Zoom>
            </div>

            <div className="col-md-4">
              <Zoom cascade>
                <div className="footer-right">
                  <p className="footer-company-about">
                    <span>About the company</span>
                    Aditya Agro Farm offers countless activities and adventures
                    to fulfil your every desire as actively as your heart
                    desires.
                  </p>
                  <div className="footer-icons">
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                    <a href="#">
                      <i className="fa fa-instagram" />
                    </a>
                    <a href="#">
                      <i className="fa fa-google" />
                    </a>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
